* {
  box-sizing: border-box;
}

.date-picker-wrapper {
    background-color: #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 60px;
    border-radius: 16px 16px 0 0;
    width: 100%;
}
.date-picker-wrapper > div {
    width: 100%;
    max-width: 550px;
}
.react-datepicker {
  font-family: plex;
}
.react-datepicker__navigation--next.react-datepicker__navigation--next {
    top: unset;
    right: 10%;
    color: #1e94ea;
}
.react-datepicker__navigation--previous.react-datepicker__navigation--previous {
    top: unset;
    left: 10%;
    color: #1e94ea;
}
.react-datepicker__day {
  font-weight: bold;
}
.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next::before {
  border-color: #1e94ea;
}
.react-datepicker__navigation--previous.react-datepicker__navigation--previous .react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous::before{
    border-color: #1e94ea;
}
.react-datepicker.react-datepicker {
    padding: 16px;
    padding-bottom: 32px;
    border-radius: 16px 16px 0 0;
}
.react-datepicker__month-container.react-datepicker__month-container {
    border-radius: 16px;
}
.react-datepicker,
.react-datepicker__header,
.react-datepicker__month-container {
    min-width: 100%;
}
.react-datepicker__week {
    margin-bottom: 1rem;
}
.react-datepicker__current-month.react-datepicker__current-month {
    font-weight: normal;
}
.react-datepicker__header.react-datepicker__header.react-datepicker__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    font-weight: bold;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    text-align: initial;
    width: 100%;
}
.react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.react-datepicker.react-datepicker {
    border: none;
    background-color: #eee;
}
.react-datepicker__day.react-datepicker__day:hover,
.react-datepicker__day--selected.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected.react-datepicker__day--selected {
    border-radius: 50%;
}
.react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: unset;
}
.react-datepicker__day--disabled:focus,
.react-datepicker__day--disabled.react-datepicker__day--keyboard-selected {
    color: #ccc;
}

.Dropdown-root {
  border: solid 1px #888;
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 50;
  padding: 8px;
  font-family: quicksand;
  font-size: 20px;
}

.Dropdown-control {
  height: 100%;
  padding: 8px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.Dropdown-menu {
  background-color: #fff;
  border: solid 1px #aaa;
  height: 200px;
  overflow: scroll;
  position: relative;
  width: 100%;
  top: 8px;
  left: -9px;
}

.Dropdown-option {
  border-bottom: solid 1px #aaa;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.Dropdown-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}

.Dropdown-placeholder.is-selected {
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
input[type='number'] {

}

@keyframes playbtn {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes followPlayBtn {
  from {
    transform: rotate(-35deg);
  }
  to {
    transform: rotate(35deg);
  }
}
